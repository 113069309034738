import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import ShopApiService from "@/core/services/api.service.shop-api";
import MediaApiService from "@/core/services/api.service.media";
import MobileApiService from "@/core/services/api.service.mobileapi";
import { VALIDATORS } from "@/common/validators";
import { AVAILABILITY, RESTRICTIONS, PRODUCT_TYPE } from "./add-new-shop-post-enums";
import { COLOR_DEFAULTS } from "@/common/color-defaults";
import WindowRedirectService from "@/core/services/window-redirect.service";
import ProductApiService from "@/core/services/api.service.product";
import moment from "moment";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "add-new-shop-post",
  data() {
    return {
      //settings
      shops: [],
      shopsLoading: false,
      cities: [],
      citiesLoading: false,
      categories: [],
      categoriesLoading: false,
      products: [],
      productsLoading: false,
      productData: null,
      productDataLoading: false,
      selectedProduct: null,
      orderOptions: [],
      orderOptionsLoading: false,
      selectedParentCategories: [],
      subcategories: [],
      tagInput: null,
      availabilityEnums: AVAILABILITY,
      restrictionEnum: RESTRICTIONS,
      restrictionTypeSelect: RESTRICTIONS.VALID_UNTIL_DATE,
      dateFormat: "DD.MM.yyyy",
      localMediaList: [],
      productTypeEnum: PRODUCT_TYPE,
      productTypeSelect: PRODUCT_TYPE.PRODUCT,
      colors: COLOR_DEFAULTS,
      sizes: [],
      productNameFilter: "",
      onFilterSearch: null,
      disableQuantity: false,
      //form settngs
      valid: false,
      product: {
        id: null,
        name: "",
        price: null,
        inStock: null,
        reserved: null,
        shopId: null,
        shopSlug: null,
        thumbPath1: "",
        thumbPath2: "",
        thumbPath3: "",
        services: "",
        categoryIds: null,
        category: null,
        description: "",
        published: true,
        lowestPrice: null,
        purchasePrice: null,
        code: "",
        supplierCode: ""
      },
      form: {
        availability: AVAILABILITY.QUANTITY,
        categoryIds: [],
        cityIds: [],
        discountPercent: null,
        numberOfUnits: null,
        parentCategoryIds: [],
        productId: null,
        productVariations: {
          propertiesType: PRODUCT_TYPE.PRODUCT,
          variations: [
            {
              quantity: null,
              variationId: null,
              properties: [
                {
                  propertyName: "",
                  propertyValue: "",
                  propertyCategory: ""
                }
              ]
            }
          ]
        },
        quantity: null,
        shopId: null
      },
      countries: [],
      selectedCountry: [],
      rules: {
        required: VALIDATORS.required,
        requiredTags: [() => this.form.tags?.length > 0 || "Tags are required"],
        between: VALIDATORS.between(1, 100)
      }
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await CountryService.init();
    await ShopApiService.init();
    await MobileApiService.init();
    await MediaApiService.init();
    await ProductApiService.init();

    this.getCountries();
    this.getShops();
    this.getCities();
  },
  watch: {
    onFilterSearch(val) {
      this.productNameFilter = val;
      this.getProducts();
    }
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res || !res.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.getShops();
      this.getCities();
    },
    getShops() {
      if (this.shopsLoading) return;

      const params = {};
      if (this.selectedCountry) {
        params["countryId"] = this.selectedCountry.id;
      }

      this.shopsLoading = true;
      ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.shops = res.data;
        })
        .finally(() => {
          this.shopsLoading = false;
        });
    },
    getCities() {
      const params = {};

      if (this.selectedCountry) {
        params["countryId"] = this.selectedCountry.id;
      }

      if (this.citiesIsLoading) return;

      this.citiesLoading = true;
      ApiService.query("api/cities", { params })
        .then(res => {
          this.cities = res.data;
        })
        .finally(() => {
          this.citiesLoading = false;
        });
    },
    onShopChange(event) {
      if (!event) {
        this.categories = [];
      } else {
        this.getCategories();
        // this.getOrderOptions();
        //this.getSizeProperties();
        this.getProducts();
      }
    },
    getProducts() {
      const params = {
        published: true,
        name: this.productNameFilter ? this.productNameFilter : null
      };
      this.productsLoading = true;
      ShopApiService.query(`/shop-visited/${this.form.shopId}/all-items`, {
        params
      })
        .then(res => {
          this.products = res.data.items;
          // console.log(this.products);
        })
        .finally(() => {
          this.productsLoading = false;
        });
    },
    loadProductData(product) {
      this.productDataLoading = true;
      ProductApiService.getProduct(`api/main-feed/product-item`, product.id)
        .then(res => {
          this.productData = res.data;
          this.mapProductDataToForm();
        })
        .finally(() => {
          this.productDataLoading = false;
        });
    },
    mapProductDataToForm() {
      this.form.productVariations.variations = [];
      this.form.productVariations.propertiesType = this.productData?.catalog.propertiesType;

      this.productData?.catalog?.propertyTypesCatalog.forEach(property => {
        const variation = {
          quantity: property?.quantity,
          variationId: property?.variationId,
          properties: []
        };

        property.properties.forEach(prop => {
          variation.properties.push({
            propertyName: prop?.name,
            propertyValue: prop?.value,
            propertyCategory: prop?.category
          });
        });

        this.form.productVariations.variations.push(variation);
      });
    },
    getCategories() {
      const params = {
        type: "POST_PRODUCT_CREATE",
        lang: "ENG",
        shopId: this.form.shopId
      };

      this.categoriesLoading = true;
      ShopApiService.query("/relevant-categories", {
        params
      })
        .then(res => {
          this.categories = res.data;
        })
        .finally(() => {
          this.categoriesLoading = false;
        });
    },
    getSizeProperties() {
      MobileApiService.post("JsonPropertyGet", {
        shopId: this.form.shopId,
        productTypeId: this.form.productTypeId
      }).then(res => {
        const data = res.data?.propertyTypes || [];
        const formattedData = data
          .map(item => {
            item.properties.forEach(innerVal => {
              innerVal["group"] = item.name;
            });

            return item.properties;
          })
          .flat();

        this.sizes = formattedData;
      });
    },
    getOrderOptions() {
      this.orderOptionsLoading = true;
      ShopApiService.query(`/${this.form.shopId}/order-options`)
        .then(res => {
          this.orderOptions = res.data;
          this.form.orderOptionTypes =
            this.orderOptions?.filter(item => item.active)?.map(item => item.type) || [];
        })
        .finally(() => {
          this.orderOptionsLoading = false;
        });
    },
    onParentCategoryChange() {
      if (!this.form.parentCategoryIds?.length) {
        this.subcategories = [];
      } else {
        const selectedParentCategoryArr =
          this.categories?.filter(item => this.form.parentCategoryIds?.includes(item.id)) || [];

        this.subcategories = selectedParentCategoryArr
          .map(item => item?.subcategories || [])
          .flat();
      }
    },
    createTag(event) {
      this.form.tags.push(event.target.value);
      this.tagInput = "";
    },
    removeTag(event) {
      this.form.tags = this.form.tags.filter(item => item !== event);
    },
    getNewPrice() {
      const discount = this.form.discountPercent / 100;
      const priceDifference = discount * this.product.price;
      const price = this.product.price - priceDifference;
      return isNaN(price) ? 0 : price;
    },
    onRestrictionChange(event) {
      if (event === RESTRICTIONS.VALID_UNTIL_DATE) {
        this.form.restrictions = RESTRICTIONS.VALID_UNTIL_DATE;
      } else {
        this.form.restrictions = "";
      }
    },

    previousState() {
      if (WindowRedirectService.isWindowRedirected()) {
        const events = WindowRedirectService.getEvents(window);
        events.triggerClose();
      } else {
        this.$router.go(-1);
      }
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.onSave();
      }
    },

    async onSave() {
      try {
        this.form.productId = this.product.id;
        this.form.title = this.product.name;
        this.form.description = this.product.description;
        this.form.discountPercent /= 100;
        const postModel = { ...this.form };

        /*     if (postModel.properties?.length) {
          //postModel.quantity = 0;
          postModel.properties.forEach(item => {
            if (item.quantity) {
              item.quantity = +item.quantity;
              postModel.quantity += item.quantity;
            }
          });
        }*/

        /*        if (postModel.services?.length) {
          postModel.quantity = 0;
          postModel.services.forEach(item => {
            delete item.local;
            if (item.people) {
              item.people = +item.people;
              postModel.quantity += item.people;
            }
          });
        }*/

        /*   if (postModel.quantity) {
          postModel.numberOfUnits = +postModel.quantity;
        }*/

        if (postModel.validUntilDate) {
          postModel.validUntilDate = moment(postModel.validUntilDate).format(this.dateFormat);
        }

        /*        if (postModel.mediaList?.length) {
          const params = {
            targetType: "PRODUCT",
            mediaType: "IMAGE"
          };

          postModel.mediaList = await MediaApiService.mediaUpload(postModel.mediaList, params).then(
            res => {
              // FORMAT IMAGES TO MATCH ANDROID REQUEST
              const formatted = res.data.map(item => {
                item["fileSize"] = +item.fileSize;
                item["thumb1"] = item?.thumbnail1;
                delete item?.thumbnail1;
                delete item.thumb1?.originalFileName;
                item["thumb2"] = item?.thumbnail2;
                delete item?.thumbnail2;
                delete item.thumb2?.originalFileName;
                item["thumb3"] = item?.thumbnail3;
                delete item?.thumbnail3;
                delete item.thumb3?.originalFileName;

                return item;
              });

              return formatted;
            }
          );
        }*/
        //console.log(postModel);
        ShopApiService.create("/product-post", postModel).then(() => {
          this.previousState();
          this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
            title: this.name,
            solid: true
          });
        });
      } catch (error) {
        this.$bvToast.toast("Error: " + error, {
          solid: true,
          variant: "error",
          autoHideDelay: 2000
        });
      }
    },
    getColor(properties) {
      const colorProperty = properties.find(prop => prop.propertyCategory === "Colors");
      return colorProperty ? colorProperty.propertyValue : "";
    },
    getSize(properties) {
      const sizeProperty = properties.find(prop => prop.propertyCategory === "Sizes");
      return sizeProperty ? sizeProperty.propertyValue : "";
    },
    getTime(properties) {
      const colorProperty = properties.find(prop => prop.propertyCategory === "Time");
      return colorProperty ? colorProperty.propertyValue : "";
    },
    getDate(properties) {
      const sizeProperty = properties.find(prop => prop.propertyCategory === "Date");
      return sizeProperty ? sizeProperty.propertyValue : "";
    },
    decrementQuantity(variation) {
      if (variation.quantity > 0) {
        variation.quantity--;
        this.updateForm();
      }
    },
    incrementQuantity(variation) {
      const maxValue = this.productData.catalog.propertyTypesCatalog.find(
        item => item.variationId === variation.variationId
      )?.quantity;
      if (variation.quantity < maxValue) {
        variation.quantity++;
        this.updateForm();
      }
    },
    getTotalQuantity() {
      const totalQtty = this.form.productVariations.variations.reduce(
        (total, variation) => total + variation.quantity,
        0
      );
      this.form.numberOfUnits = totalQtty;
      this.form.quantity = totalQtty;
      return totalQtty;
    },
    updateForm() {
      this.$set(this.form, "productVariations", { ...this.form.productVariations });
    },
    getMaxAvailableQuantity(variation) {
      const maxQuantity = this.productData?.catalog?.propertyTypesCatalog.find(
        item => item.variationId === variation.variationId
      )?.quantity;
      return maxQuantity || 0;
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.newPost");
    }
  }
};
